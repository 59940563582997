import { render, staticRenderFns } from "./SetState.vue?vue&type=template&id=838706b6&scoped=true&"
import script from "./SetState.vue?vue&type=script&lang=js&"
export * from "./SetState.vue?vue&type=script&lang=js&"
import style0 from "./SetState.vue?vue&type=style&index=0&id=838706b6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "838706b6",
  null
  
)

export default component.exports